import React, { useState, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  Box,
  useMediaQuery,
  Drawer,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import GitHubIcon from '@mui/icons-material/GitHub';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { motion } from 'framer-motion';
import StyledButton from '../common/StyledButton';

const Navbar = memo(
  ({ loggedIn, emailVerified, role, handleLogoutClick, themeMode, toggleTheme }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const location = useLocation();

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const navItems = loggedIn && emailVerified
      ? [
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
          { text: 'Analysis', icon: <DashboardIcon />, path: '/dashboard/code-analysis' },
          { text: 'Subscription', icon: <SubscriptionsIcon />, path: '/dashboard/subscription' },
          { text: 'GitHub', icon: <GitHubIcon />, path: '/dashboard/github' },
          { text: 'Profile', icon: <PersonIcon />, path: '/dashboard/user' },
          ...(role === 'Admin'
            ? [{ text: 'Admin', icon: <AdminPanelSettingsIcon />, path: '/dashboard/admin' }]
            : []),
        ]
      : [
          { text: 'Login', icon: <LoginIcon />, path: '/login' },
          { text: 'Sign Up', icon: <PersonAddIcon />, path: '/register' },
        ];

    const drawerVariants = {
      hidden: { x: '100%', opacity: 0 },
      visible: { x: 0, opacity: 1, transition: { duration: 0.4, ease: 'easeOut' } },
      exit: { x: '100%', opacity: 0, transition: { duration: 0.3, ease: 'easeIn' } },
    };

    const itemVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: { duration: 0.3, delay: i * 0.1 },
      }),
    };

    const drawerContent = (
      <motion.div
        variants={drawerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        sx={{
          height: '100%',
          bgcolor: themeMode === 'dark' ? '#1A1A1A' : '#42a5f5',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2,
            bgcolor: 'inherit',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={{
                color: '#fff',
                '&:hover': {
                  transform: 'scale(1.1)',
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {navItems.map((item, index) => (
              <motion.div key={item.text} variants={itemVariants} custom={index}>
                <StyledButton
                  component={item.path ? Link : 'button'}
                  to={item.path}
                  onClick={handleDrawerToggle}
                  startIcon={item.icon}
                  fullWidth
                  isActive={item.path === location.pathname}
                >
                  <Typography variant="button" sx={{ fontSize: '1rem' }}>
                    {item.text}
                  </Typography>
                </StyledButton>
              </motion.div>
            ))}
            {loggedIn && emailVerified && (
              <motion.div variants={itemVariants} custom={navItems.length}>
                <StyledButton
                  onClick={(e) => {
                    handleLogoutClick(e);
                    handleDrawerToggle();
                  }}
                  startIcon={<LogoutIcon />}
                  fullWidth
                >
                </StyledButton>
              </motion.div>
            )}
            <motion.div variants={itemVariants} custom={navItems.length + 1}>
              <StyledButton
                onClick={() => {
                  toggleTheme();
                  handleDrawerToggle();
                }}
                startIcon={themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                fullWidth
              >
                <Typography variant="button" sx={{ fontSize: '1rem' }}>
                  {themeMode === 'dark' ? 'Light Mode' : 'Dark Mode'}
                </Typography>
              </StyledButton>
            </motion.div>
          </Box>
        </Box>
      </motion.div>
    );

    return (
      <>
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            bgcolor: 'transparent',
            boxShadow: themeMode === 'dark'
              ? '0 4px 15px rgba(0, 0, 0, 0.5)'
              : '0 4px 15px rgba(25, 118, 210, 0.4)',
            borderBottom: '1px solid',
            borderColor: themeMode === 'dark' ? theme.palette.grey[800] : 'divider',
            background: themeMode === 'dark'
              ? 'rgba(0, 0, 0, 0.5)'
              : 'linear-gradient(135deg, #1976d2 0%, #0288d1 100%)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: themeMode === 'dark'
                ? '0 6px 20px rgba(0, 0, 0, 0.7)'
                : '0 6px 20px rgba(25, 118, 210, 0.5)',
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: themeMode === 'dark'
                ? 'radial-gradient(circle, rgba(25, 118, 210, 0.2), transparent)'
                : 'radial-gradient(circle, rgba(200, 220, 255, 0.2), transparent)',
              zIndex: -1,
            },
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
              minHeight: { xs: 56, sm: 64 },
              px: { xs: 1, sm: 2, md: 4 },
              alignItems: 'center',
            }}
          >
            <Box
              component={Link}
              to={loggedIn && emailVerified ? '/dashboard' : '/'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box
                component="img"
                src="/assets/logos/logo.svg"
                alt="CRYPEX Logo"
                sx={{
                  width: { xs: '120px', sm: '150px', md: '180px' },
                  height: { xs: '40px', sm: '48px' },
                  objectFit: 'contain',
                }}
              />
            </Box>
            {!isMobile ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 1, sm: 1.5, md: 2 },
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {navItems.map((item, index) => (
                  <motion.div
                    key={item.text}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <StyledButton
                      component={item.path ? Link : 'button'}
                      to={item.path}
                      startIcon={item.icon}
                      isActive={item.path === location.pathname}
                    >
                      <Typography variant="button" sx={{ fontSize: '1rem' }}>
                        {item.text}
                      </Typography>
                    </StyledButton>
                  </motion.div>
                ))}
                {loggedIn && emailVerified && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: navItems.length * 0.1 }}
                  >
                    <StyledButton onClick={handleLogoutClick} startIcon={<LogoutIcon />}>
                    </StyledButton>
                  </motion.div>
                )}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: (navItems.length + 1) * 0.1 }}
                >
                  <StyledButton
                    onClick={toggleTheme}
                    startIcon={themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                  >
                  </StyledButton>
                </motion.div>
              </Box>
            ) : (
              <IconButton
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  color: themeMode === 'dark' ? '#F8F8F2' : '#1976d2',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    bgcolor: 'rgba(25, 118, 210, 0.1)',
                    boxShadow: '0 2px 8px rgba(25, 118, 210, 0.3)',
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              width: 280,
              bgcolor: themeMode === 'dark' ? '#1A1A1A' : '#42a5f5',
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.loggedIn === nextProps.loggedIn &&
      prevProps.emailVerified === nextProps.emailVerified &&
      prevProps.role === nextProps.role &&
      prevProps.handleLogoutClick === nextProps.handleLogoutClick &&
      prevProps.themeMode === nextProps.themeMode &&
      prevProps.toggleTheme === nextProps.toggleTheme
    );
  }
);

Navbar.displayName = 'Navbar';
export default Navbar;