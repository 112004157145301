import React, { Suspense, lazy, memo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useAuth } from './context/AuthContext';
import Loading from './components/common/Loading';

const SubscriptionManagementWrapper = lazy(() => import('./components/features/Stripe/SubscriptionManagementWrapper'));
const LandingPage = lazy(() => import('./components/pages/Landing/Index'));
const Login = lazy(() => import('./components/pages/Login'));
const Register = lazy(() => import('./components/pages/Register'));
const ForgotPassword = lazy(() => import('./components/pages/ForgotPassword'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/pages/TermsOfService'));
const ContactUs = lazy(() => import('./components/pages/ContactUs'));
const Dashboard = lazy(() => import('./components/features/Dashboard/DashboardMain'));
const CodeAnalysis = lazy(() => import('./components/features/CodeAnalysis/CodeAnalysis'));
const AllAnalyses = lazy(() => import('./components/features/AllAnalyses/AllAnalyses'));
const GitHubIntegration = lazy(() => import('./components/features/GitHub/GitHubIntegration'));
const UserManagement = lazy(() => import('./components/features/UserManagement/UserManagement'));
const AdminPage = lazy(() => import('./components/features/Admin/AdminPage'));

const RoutesConfig = memo(
  ({ previousAnalyses, setPreviousAnalyses, themeMode, apiRequest, updateRequestCount }) => {
    const { loggedIn, role, roleConfig, emailVerified, isLoading, requestCount } = useAuth();

    if (isLoading) {
      return <Loading />;
    }

    return (
      <AnimatePresence mode="wait">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Suspense fallback={<Loading />}><LandingPage /></Suspense>} />
          <Route path="/login" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />
          <Route path="/register" element={<Suspense fallback={<Loading />}><Register /></Suspense>} />
          <Route path="/forgot-password" element={<Suspense fallback={<Loading />}><ForgotPassword /></Suspense>} />
          <Route path="/privacy" element={<Suspense fallback={<Loading />}><PrivacyPolicy /></Suspense>} />
          <Route path="/terms" element={<Suspense fallback={<Loading />}><TermsOfService /></Suspense>} />
          <Route path="/contact" element={<Suspense fallback={<Loading />}><ContactUs loggedIn={loggedIn} /></Suspense>} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <Dashboard role={role} requestCount={requestCount} previousAnalyses={previousAnalyses} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/code-analysis"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <CodeAnalysis
                    apiRequest={apiRequest}
                    role={role}
                    requestCount={requestCount}
                    previousAnalyses={previousAnalyses}
                    themeMode={themeMode}
                  />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/all-analyses"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <AllAnalyses previousAnalyses={previousAnalyses} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/github"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <GitHubIntegration
                    role={role}
                    requestCount={requestCount}
                    updateRequestCount={updateRequestCount}
                    roleConfig={roleConfig}
                  />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/subscription"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <SubscriptionManagementWrapper role={role} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/user"
            element={
              loggedIn && emailVerified ? (
                <Suspense fallback={<Loading />}>
                  <UserManagement loggedIn={loggedIn} />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />
          <Route
            path="/dashboard/admin"
            element={
              loggedIn && emailVerified && role === 'Admin' ? (
                <Suspense fallback={<Loading />}>
                  <AdminPage />
                </Suspense>
              ) : (
                <Navigate to={loggedIn ? '/verify-otp' : '/login'} replace />
              )
            }
          />

          {/* Catch-all Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AnimatePresence>
    );
  },
  (prevProps, nextProps) =>
    prevProps.previousAnalyses === nextProps.previousAnalyses &&
    prevProps.themeMode === nextProps.themeMode &&
    prevProps.apiRequest === nextProps.apiRequest &&
    prevProps.updateRequestCount === nextProps.updateRequestCount
);

RoutesConfig.displayName = 'RoutesConfig';
export default RoutesConfig;