import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useState, useMemo, createContext, useContext } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [themeMode, setThemeMode] = useState(localStorage.getItem('theme') || 'light');

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: themeMode,
        primary: { main: '#1976d2', light: '#42a5f5', dark: '#1565c0' },
        secondary: { main: '#0288d1', light: '#4fc3f7', dark: '#01579b' },
        background: {
          default: themeMode === 'light' ? '#fafafa' : '#0d1b2a',
          paper: themeMode === 'light' ? '#ffffff' : '#1b263b',
        },
        text: {
          primary: themeMode === 'light' ? '#212121' : '#bbdefb',
          secondary: themeMode === 'light' ? '#757575' : '#90caf9',
        },
      },
      typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: { fontWeight: 700 },
        h2: { fontWeight: 700 },
        h3: { fontWeight: 700 },
        h4: { fontWeight: 700 },
        h6: { fontWeight: 600 },
        body1: { fontSize: '1rem', lineHeight: 1.5 },
      },
      shape: { borderRadius: 4 }, // Changed from 12 to 4
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              padding: '12px 24px',
              borderRadius: 4, // Changed from 8 to 4
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': { boxShadow: '0 4px 8px rgba(0,0,0,0.2)' },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: themeMode === 'light'
                ? '0 4px 12px rgba(0,0,0,0.1)'
                : '0 4px 12px rgba(0,0,0,0.3)',
              transition: 'box-shadow 0.3s ease',
              '&:hover': { boxShadow: '0 6px 16px rgba(0,0,0,0.2)' },
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              background: themeMode === 'light'
                ? 'linear-gradient(135deg, #1976d2 0%, #0288d1 100%)'
                : 'linear-gradient(135deg, #1565c0 0%, #01579b 100%)',
            },
          },
        },
      },
    });
  }, [themeMode]);

  const toggleTheme = () => {
    const newMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newMode);
    localStorage.setItem('theme', newMode);
  };

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);