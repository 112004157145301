import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from './api';

export const useApiCall = (url, method = 'GET', initialData = null, isLoginCheck = false) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const callApi = useCallback(
    async (setLoggedIn, customData = initialData) => {
      setLoading(true);
      setError(null); // Reset previous error
      // Optionally, don’t reset response unless it’s a new call type
      // setResponse(null); // Comment this out if you want to persist last response

      try {
        const result = await apiRequest(url, method, customData, isLoginCheck, setLoggedIn, setError);
        setResponse(result.data); // Store the data from the API response
        return result; // Return the full result (e.g., { data, status })
      } catch (err) {
        const status = err.response?.status;
        const errorMessage = err.response?.data?.message || err.message || 'API call failed';
        const requiresOtp = err.response?.data?.requires_otp;

        // Handle specific error statuses
        if (status === 401) {
          if (setLoggedIn) setLoggedIn(false);
          navigate('/login');
        } else if (status === 403 && requiresOtp) {
          if (setLoggedIn) setLoggedIn(false);
          navigate('/verify-otp');
        } else {
          setError(errorMessage); // Set error for display in UI
        }

        throw err; // Propagate error to caller
      } finally {
        setLoading(false);
      }
    },
    [url, method, initialData, isLoginCheck, navigate] // Dependencies are stable
  );

  return { loading, error, response, callApi };
};