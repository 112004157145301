import React, { useState, useEffect, useCallback, memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, Alert, CssBaseline } from '@mui/material';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import RoutesConfig from './RoutesConfig';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Loading from './components/common/Loading';
import styles from './styles/App.module.css';
import { apiRequest } from './services/api';
import { useTheme } from './context/ThemeContext';

// Memoized AppContent with stable props
const AppContent = memo(
  ({
    previousAnalyses,
    setPreviousAnalyses,
    userData,
    setUserData,
    handleLogout: externalHandleLogout,
    updateRequestCount: externalUpdateRequestCount,
    error,
    setError,
  }) => {
    const { loggedIn, emailVerified, logout, updateRequestCount: updateAuthRequestCount, isLoading } = useAuth();
    const { themeMode } = useTheme();

    // Memoized local handlers
    const localHandleLogout = useCallback(() => {
      logout();
      setPreviousAnalyses([]);
      setError(null);
    }, [logout, setPreviousAnalyses, setError]);

    const localUpdateRequestCount = useCallback(
      (increment) => {
        setUserData((prev) => {
          const newCount = (prev.request_count || 0) + increment;
          apiRequest('/api/user/update-request-count', 'PATCH', { request_count: newCount }).catch((err) => {
            console.error('Failed to update request count:', err);
            setError(err.response?.data?.message || 'Failed to update request count');
          });
          updateAuthRequestCount(increment);
          return { ...prev, request_count: newCount };
        });
      },
      [updateAuthRequestCount, setError, setUserData]
    );

    useEffect(() => {
      if (loggedIn && emailVerified) {
        setUserData((prev) => ({
          ...prev,
          username: prev.username || '',
          email: prev.email || '',
          phone: prev.phone || '',
          name: prev.name || '',
          request_count: prev.request_count || 0,
        }));
      } else if (!isLoading) {
        setUserData({});
      }
    }, [loggedIn, emailVerified, isLoading, setUserData]);

    if (isLoading) {
      return <Loading />;
    }

    // Use external handlers if provided, otherwise local ones
    const finalHandleLogout = externalHandleLogout || localHandleLogout;
    const finalUpdateRequestCount = externalUpdateRequestCount || localUpdateRequestCount;

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: themeMode === 'light' ? '#fafafa' : '#0d1b2a',
        }}
        className={styles.app}
      >
        <Box component="header">
          <Header handleLogout={finalHandleLogout} />
        </Box>
        <Box
          component="main"
          className={styles.content}
          sx={{
            flexGrow: 1,
            px: { xs: 2, md: 3 },
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          <RoutesConfig
            previousAnalyses={previousAnalyses}
            setPreviousAnalyses={setPreviousAnalyses}
            userData={userData}
            themeMode={themeMode}
            apiRequest={apiRequest}
            updateRequestCount={finalUpdateRequestCount}
          />
        </Box>
        <Box component="footer">
          <Footer themeMode={themeMode} />
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.previousAnalyses === nextProps.previousAnalyses &&
    prevProps.userData === nextProps.userData &&
    prevProps.handleLogout === nextProps.handleLogout &&
    prevProps.updateRequestCount === nextProps.updateRequestCount &&
    prevProps.error === nextProps.error
);

AppContent.displayName = 'AppContent';

const App = () => {
  const [previousAnalyses, setPreviousAnalyses] = useState([]);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState(null);

  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <CssBaseline />
          <AppContent
            previousAnalyses={previousAnalyses}
            setPreviousAnalyses={setPreviousAnalyses}
            userData={userData}
            setUserData={setUserData}
            handleLogout={null} // Can define a stable handler here if needed
            updateRequestCount={null} // Can define a stable handler here if needed
            error={error}
            setError={setError}
          />
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;