import React from 'react';
import { Box, CircularProgress, Typography, keyframes } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme as useCustomTheme } from '../../context/ThemeContext';

// Animation keyframes (reused from Dashboard.jsx and enhanced)
const pulse = keyframes`
  0% { transform: scale(0.95); opacity: 0.7; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.95); opacity: 0.7; }
`;

// Animation variants (aligned with Dashboard.jsx)
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
};

const Loading = () => {
  const { themeMode } = useCustomTheme();

  // Text color and background styles from Dashboard.jsx
  const textColor = themeMode === 'dark' ? 'white' : 'black';
  const backgroundGradient = themeMode === 'dark'
    ? 'radial-gradient(circle at 50% 30%, rgba(25, 118, 210, 0.1), transparent 70%)'
    : 'radial-gradient(circle at 50% 30%, rgba(200, 220, 255, 0.1), transparent 70%)';

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        zIndex: 9999,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: backgroundGradient, // Integrated gradient from Dashboard.jsx
          zIndex: -1,
        },
      }}
      component={motion.div}
      initial="hidden"
      animate="visible"
      variants={fadeInUp}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 100,
          height: 100,
          borderRadius: '50%',
          bgcolor: themeMode === 'dark' ? 'primary.dark' : 'grey.100', // Aligned with Dashboard.jsx tones
          boxShadow: themeMode === 'dark'
            ? '0 8px 24px rgba(25, 118, 210, 0.3)' // Matches hoverLift shadow from Dashboard.jsx
            : '0 8px 24px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CircularProgress
          size={60}
          thickness={5}
          sx={{
            color: 'primary.main',
            animation: `${pulse} 2s ease-in-out infinite`,
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
            },
          }}
        />
      </Box>

      <Typography
        variant="h6"
        sx={{
          mt: 3,
          color: textColor, // Integrated from Dashboard.jsx
          fontWeight: 600,
          letterSpacing: 0.5,
          animation: `${pulse} 2s ease-in-out infinite`,
          background: 'linear-gradient(90deg, #1976d2, #42a5f5)', // Gradient text from Dashboard.jsx
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Loading...
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          color: textColor, // Updated to match Dashboard.jsx textColor
          fontSize: '0.8rem',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            background: 'linear-gradient(90deg, #1976d2, #42a5f5)', // Gradient text from Dashboard.jsx
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Built with ❤️ by the Crypex Team
        </Typography>
      </Box>
    </Box>
  );
};

export default Loading;