import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Link as MuiLink,
  IconButton,
  Divider,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme as useCustomTheme } from '../../context/ThemeContext';

// Animation variants
const footerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
};

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, delay: i * 0.1 },
  }),
};

function Footer({ themeMode: propThemeMode }) {
  const muiTheme = useTheme();
  const { themeMode: contextThemeMode } = useCustomTheme();
  const themeMode = propThemeMode || contextThemeMode;

  const iconStyles = {
    color: themeMode === 'dark' ? muiTheme.palette.grey[400] : muiTheme.palette.grey[700],
  };

  return (
    <Box
      component={motion.footer}
      initial="hidden"
      animate="visible"
      variants={footerVariants}
      sx={{ width: '100%' }}
    >
      <Box
        sx={{
          py: 4,
          px: { xs: 2, sm: 3, md: 4 },
          bgcolor: 'background.default',
          borderTop: 1,
          borderColor: 'divider',
          borderRadius: 2,
          boxShadow: themeMode === 'dark'
            ? '0 -4px 12px rgba(0,0,0,0.5)'
            : '0 -4px 12px rgba(0,0,0,0.1)',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: themeMode === 'dark'
              ? 'radial-gradient(circle at 50% 30%, rgba(25, 118, 210, 0.1), transparent 70%)'
              : 'radial-gradient(circle at 50% 30%, rgba(200, 220, 255, 0.1), transparent 70%)',
            zIndex: -1,
          },
        }}
      >
        <Grid container spacing={4} alignItems="center" justifyContent="space-between">
          {/* Left Section: Copyright */}
          <Grid item xs={12} sm={4}>
            <motion.div variants={itemVariants} custom={0}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'medium',
                  textAlign: { xs: 'center', sm: 'left' },
                  color: themeMode === 'dark' ? 'grey.400' : 'text.secondary',
                }}
              >
                © {new Date().getFullYear()} Crypex. All rights reserved.
              </Typography>
            </motion.div>
          </Grid>

          {/* Center Section: Links */}
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                flexWrap: 'wrap',
                '& > *': { flex: '1 1 auto', minWidth: '100px' },
              }}
            >
              {[
                { text: 'Privacy Policy', href: '/privacy' },
                { text: 'Terms of Service', href: '/terms' },
                { text: 'Contact Us', href: '/contact' },
              ].map((link, index) => (
                <motion.div
                  key={link.text}
                  variants={itemVariants}
                  custom={index + 1}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <MuiLink
                    component={RouterLink}
                    to={link.href}
                    underline="none"
                    sx={{
                      color: themeMode === 'dark' ? muiTheme.palette.grey[400] : muiTheme.palette.grey[700],
                      fontWeight: 'medium',
                      fontSize: '0.9rem',
                      '&:hover': {
                        color: themeMode === 'dark' ? muiTheme.palette.primary.light : muiTheme.palette.primary.dark,
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {link.text}
                  </MuiLink>
                </motion.div>
              ))}
            </Box>
          </Grid>

          {/* Right Section: Social Icons */}
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-end' },
                gap: 2,
              }}
            >
              {[
                { href: 'https://github.com', icon: <GitHubIcon /> },
                { href: 'https://twitter.com', icon: <TwitterIcon /> },
                { href: 'https://linkedin.com', icon: <LinkedInIcon /> },
              ].map((social, index) => (
                <motion.div
                  key={social.href}
                  variants={itemVariants}
                  custom={index + 4}
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <IconButton
                    component="a"
                    href={social.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      ...iconStyles,
                      '&:hover': {
                        color: themeMode === 'dark' ? muiTheme.palette.primary.light : muiTheme.palette.primary.dark,
                      },
                    }}
                  >
                    {social.icon}
                  </IconButton>
                </motion.div>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Divider and Bottom Text */}
        <Divider
          sx={{
            my: 3,
            borderColor: themeMode === 'dark' ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)',
          }}
        />
        <motion.div variants={itemVariants} custom={7}>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              display: 'block',
              fontStyle: 'italic',
              paddingBottom: 1,
              color: themeMode === 'dark' ? 'grey.400' : 'text.secondary',
              background: 'linear-gradient(90deg, #1976d2, #42a5f5)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Built with ❤️ by the Crypex Team
          </Typography>
        </motion.div>
      </Box>
    </Box>
  );
}

export default Footer;