import axios from 'axios';

const API_BASE_URL = (() => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_URL || 'https://api.crypex.net';
  }
  return process.env.REACT_APP_API_URL || 'http://127.0.0.1:8080';
})();

export const apiRequest = async (url, method = 'GET', data = null, isLoginCheck = false, setLoggedIn, setError = null) => {
  try {
    const fullUrl = `${API_BASE_URL}${url}`;
    const config = { method, url: fullUrl, data, withCredentials: true };
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(`API Error: ${url}`, error.response?.data || error.message);
    if (error.response) {
      const status = error.response.status;
      const errorMessage = error.response.data?.message || `Error: ${status} ${error.response.statusText}`;
      if (setError) setError(errorMessage);

      // Handle session expiration or authorization errors
      if ((status === 401 || (status === 403 && errorMessage.includes('role'))) && !isLoginCheck) {
        if (setLoggedIn) setLoggedIn(false);
        localStorage.removeItem('loggedIn');
      }
    } else if (setError) {
      setError('Network error or server unreachable');
    }
    throw error;
  }
};
