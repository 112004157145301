import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApiCall } from '../services/useApiCall';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState('Free Tier');
  const [requestCount, setRequestCount] = useState(0);
  const [roleConfig, setRoleConfig] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const [phone, setPhone] = useState(null);
  const [githubToken, setGithubToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const { callApi: checkLogin } = useApiCall('/api/check_login', 'GET', null, false);
  const { callApi: loginApi } = useApiCall('/api/login', 'POST', null, false);
  const { callApi: logoutApi } = useApiCall('/api/logout', 'POST', null, false);
  const { callApi: fetchRoleConfig } = useApiCall('/api/auth/role-config', 'GET', null, false);
  const { callApi: verifyOtpApi } = useApiCall('/api/verify-otp', 'POST', null, false);
  const { callApi: resendOtpApi } = useApiCall('/api/resend-otp', 'POST', null, false);

  // Initial auth check - runs only once on mount
  useEffect(() => {
    const publicRoutes = ['/', '/login', '/register', '/forgot-password', '/privacy', '/terms', '/contact', '/verify-otp'];

    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const response = await checkLogin(setLoggedIn);
        const userData = response?.data || {};

        if (userData.message === 'Logged in' && userData.email_verified) {
          setLoggedIn(true);
          setRole(userData.role || 'Free Tier');
          setRequestCount(userData.request_count || 0);
          setEmailVerified(true);
          setEmail(userData.email);
          setName(userData.name || '');
          setUsername(userData.username || '');
          setPhone(userData.phone || '');
          setGithubToken(userData.github_token || null);

          const roleConfigResponse = await fetchRoleConfig();
          const config = roleConfigResponse?.data?.roleConfig || {
            requestLimit: 10,
            features: { basicAnalysis: true, advancedAnalysis: false, chat: false },
          };
          setRoleConfig(config);

          if (['/login', '/register', '/'].includes(location.pathname)) {
            navigate('/dashboard');
          } else if (location.pathname === '/verify-otp') {
            navigate('/dashboard');
          }
        } else if (userData.message === 'Not logged in or email not verified' && userData.requires_otp) {
          setLoggedIn(false);
          setEmailVerified(false);
          setEmail(userData.email || null);
          setRoleConfig(null);
          if (location.pathname !== '/verify-otp') {
            navigate('/verify-otp');
          }
        } else {
          setLoggedIn(false);
          setEmailVerified(false);
          setEmail(null);
          setName(null);
          setUsername(null);
          setPhone(null);
          setGithubToken(null);
          setRoleConfig(null);
          if (!publicRoutes.includes(location.pathname)) {
            navigate('/login');
          }
        }
      } catch (err) {
        console.error('AuthContext - Failed to fetch user data:', err.response?.data || err.message);
        setLoggedIn(false);
        setEmailVerified(false);
        setEmail(null);
        setName(null);
        setUsername(null);
        setPhone(null);
        setGithubToken(null);
        setRoleConfig(null);
        if (!publicRoutes.includes(location.pathname)) {
          navigate('/login');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures one-time execution on mount

  // GitHub polling effect
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const githubConnected = urlParams.get('github_connected') === 'true';

    if (githubConnected && !githubToken && location.pathname === '/dashboard') {
      let isMounted = true;
      const maxAttempts = 5;
      let attempts = 0;

      const pollForGithubToken = async () => {
        while (isMounted && attempts < maxAttempts && !githubToken) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          try {
            const retryResponse = await checkLogin(setLoggedIn);
            const newGithubToken = retryResponse?.data?.github_token || null;
            if (newGithubToken && isMounted) {
              setGithubToken(newGithubToken);
              navigate({ search: '' }, { replace: true });
              break;
            }
            attempts++;
          } catch (err) {
            console.error('Polling error:', err);
            break;
          }
        }
      };

      pollForGithubToken();

      return () => {
        isMounted = false;
      };
    }
  }, [githubToken, location.search, location.pathname, checkLogin, navigate, setLoggedIn]);

  // Memoized functions
  const login = useCallback(
    async (username, password) => {
      try {
        const response = await loginApi(setLoggedIn, { username, password });
        const data = response.data;

        if (data.message === 'Login successful!') {
          setLoggedIn(true);
          setRole(data.role || 'Free Tier');
          setRequestCount(data.request_count || 0);
          setEmailVerified(true);
          setEmail(data.email);
          setName(data.name || '');
          setUsername(data.username || '');
          setPhone(data.phone || '');
          setGithubToken(data.github_token || null);

          const roleConfigResponse = await fetchRoleConfig();
          const config = roleConfigResponse?.data?.roleConfig || {
            requestLimit: 10,
            features: { basicAnalysis: true, advancedAnalysis: false, chat: false },
          };
          setRoleConfig(config);

          navigate('/dashboard');
        } else if (data.requires_otp) {
          setLoggedIn(false);
          setEmailVerified(false);
          setEmail(data.email || null);
          setRoleConfig(null);
          navigate('/verify-otp');
        } else {
          throw new Error(data.message || 'Login failed');
        }
      } catch (err) {
        console.error('Login error:', err.response?.data || err.message);
        throw err;
      }
    },
    [loginApi, fetchRoleConfig, navigate, setLoggedIn]
  );

  const logout = useCallback(
    async () => {
      try {
        await logoutApi(setLoggedIn);
        setLoggedIn(false);
        setRole('Free Tier');
        setRequestCount(0);
        setEmailVerified(false);
        setEmail(null);
        setName(null);
        setUsername(null);
        setPhone(null);
        setGithubToken(null);
        setRoleConfig(null);
        navigate('/login');
      } catch (err) {
        console.error('Logout error:', err.response?.data || err.message);
        throw err;
      }
    },
    [logoutApi, navigate, setLoggedIn]
  );

  const verifyOtp = useCallback(
    async (email, otp) => {
      try {
        const response = await verifyOtpApi(setLoggedIn, { email, otp });
        const data = response.data;

        if (data.message === 'OTP verified successfully') {
          setLoggedIn(true);
          setEmailVerified(true);
          setEmail(data.email || email);
          setName(data.name || name || '');
          setUsername(data.username || username || '');
          setPhone(data.phone || phone || '');
          setRole(data.role || 'Free Tier');
          setRequestCount(data.request_count || 0);
          setGithubToken(data.github_token || null);

          const roleConfigResponse = await fetchRoleConfig();
          const config = roleConfigResponse?.data?.roleConfig || {
            requestLimit: 10,
            features: { basicAnalysis: true, advancedAnalysis: false, chat: false },
          };
          setRoleConfig(config);

          navigate('/dashboard');
          return data;
        } else {
          throw new Error(data.message || 'OTP verification failed');
        }
      } catch (err) {
        console.error('Verify OTP error:', err.response?.data || err.message);
        throw err;
      }
    },
    [verifyOtpApi, fetchRoleConfig, navigate, name, username, phone, setLoggedIn]
  );

  const resendOtp = useCallback(
    async (email) => {
      try {
        const response = await resendOtpApi(setLoggedIn, { email });
        const data = response.data;
        return data.message || 'A new OTP has been sent to your email.';
      } catch (err) {
        console.error('Resend OTP error:', err.response?.data || err.message);
        throw new Error(err.response?.data?.message || 'Failed to resend OTP. Please try again.');
      }
    },
    [resendOtpApi, setLoggedIn]
  );

  const updateUserData = useCallback(
    (newData) => {
      setEmail(newData.email || email);
      setName(newData.name || name);
      setUsername(newData.username || username);
      setPhone(newData.phone || phone);
      setRole(newData.role || role);
      setRequestCount(newData.request_count !== undefined ? newData.request_count : requestCount);
      setGithubToken(newData.github_token || githubToken);
      setEmailVerified(newData.email_verified || emailVerified);
      if (newData.role && newData.role !== role) {
        fetchRoleConfig().then((response) => {
          setRoleConfig(response?.data?.roleConfig || null);
        });
      }
    },
    [email, name, username, phone, role, requestCount, githubToken, emailVerified, fetchRoleConfig]
  );

  const updateRequestCount = useCallback((increment) => {
    setRequestCount((prev) => prev + increment);
  }, []);

  // Memoized context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
      role,
      setRole,
      requestCount,
      updateRequestCount,
      roleConfig,
      emailVerified,
      email,
      name,
      username,
      phone,
      githubToken,
      login,
      logout,
      verifyOtp,
      resendOtp,
      isLoading,
      updateUserData,
    }),
    [
      loggedIn,
      role,
      requestCount,
      roleConfig,
      emailVerified,
      email,
      name,
      username,
      phone,
      githubToken,
      isLoading,
      login,
      logout,
      verifyOtp,
      resendOtp,
      updateUserData,
      updateRequestCount,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthContext;