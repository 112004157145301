import React, { useState, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toolbar, Alert } from '@mui/material';
import { useTheme as useThemeContext } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import Navbar from './Navbar';

const Header = memo(
  ({ handleLogout }) => {
    const { loggedIn, emailVerified, role, isLoading } = useAuth();
    const navigate = useNavigate();
    const { themeMode, toggleTheme } = useThemeContext();
    const [error, setError] = useState(null);

    const handleLogoutClick = useCallback(async () => {
      setError(null);
      try {
        await handleLogout();
        navigate('/login');
      } catch (error) {
        setError(error.response?.data?.message || 'Error logging out. Please try again.');
        console.error('Logout error:', error);
      }
    }, [handleLogout, navigate]);

    return (
      <>
        <div>
          {isLoading ? (
            <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }} /> // Minimal placeholder during loading
          ) : (
            <Navbar
              loggedIn={loggedIn}
              emailVerified={emailVerified}
              role={role}
              handleLogoutClick={handleLogoutClick}
              themeMode={themeMode}
              toggleTheme={toggleTheme}
            />
          )}
        </div>
        {!isLoading && <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }} />}
        {error && (
          <Alert
            severity="error"
            sx={{
              m: 2,
              borderRadius: 4,
              position: 'fixed',
              top: 64,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1300,
            }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.handleLogout === nextProps.handleLogout;
  }
);

Header.displayName = 'Header';
export default Header;