import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import { useTheme as useCustomTheme } from '../../context/ThemeContext';
import { alpha } from '@mui/material';

// Animation variants for the button
const buttonHover = {
  rest: { scale: 1 },
  hover: { scale: 1.05, transition: { duration: 0.3 } },
  tap: { scale: 0.95 },
};

const StyledButton = ({ children, isActive, ...props }) => {
  const { themeMode } = useCustomTheme();
  const muiTheme = useMuiTheme();

  // Check if the button is icon-only (no children or only whitespace)
  const isIconOnly = !children || (typeof children === 'string' && !children.trim());

  // Define base and active colors based on theme mode
  const baseColor = muiTheme.palette.primary.main;
  const activeColor = themeMode === 'dark'
    ? '#0d47a1' // Light blue for dark mode
    : '#0d47a1'; // Darker blue for light mode

  return (
    <motion.div
      variants={buttonHover}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      style={{ display: 'inline-block', width: props.fullWidth ? '100%' : 'auto' }}
    >
      <Button
        variant="contained"
        color="primary"
        fullWidth={props.fullWidth}
        sx={{
          borderRadius: 12,
          px: isIconOnly ? 1 : 4, // Reduced padding for icon-only buttons
          py: 1,
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          bgcolor: isActive ? activeColor : baseColor,
          color: 'white',
          '&:hover': {
            bgcolor: themeMode === 'dark'
              ? '#42a5f5'
              : muiTheme.palette.primary.dark,
            boxShadow: `0 4px 12px ${alpha(muiTheme.palette.primary.main, 0.3)}`,
          },
          '&:disabled': {
            bgcolor: muiTheme.palette.action.disabledBackground,
            color: muiTheme.palette.action.disabled,
          },
          transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
          display: 'flex',
          alignItems: 'center', // Center vertically
          justifyContent: isIconOnly ? 'center' : props.fullWidth ? 'flex-start' : 'center', // Center icons horizontally, adjust for text buttons
          height: '100%',
          minWidth: isIconOnly ? 'auto' : undefined, // Allow icon-only buttons to shrink
        }}
        {...props}
      >
        {children}
      </Button>
    </motion.div>
  );
};

export default StyledButton;